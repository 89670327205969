import './scss/style.scss';
import 'bootstrap';

import { WOW } from 'wowjs';
import { Swiper, Autoplay, Pagination } from 'swiper/js/swiper.esm.js';

import '../node_modules/simplelightbox/dist/simple-lightbox.min.js';

Swiper.use([Autoplay, Pagination]);

/*Se inicializa el plugin para las animaciones*/
var wow = new WOW({
    live: false,
    scrollContainer: null
});

/*Todos los "load" cargan los bloques recurrentes*/
$('header').load("header.html");
$('footer').load("footer.html");

$('#block-banner-internal').load("block-banner-internal.html", function(event) {
    wow.init();
});

/*Script para la galeria de imagenes*/
if ($.contains(document.body, document.getElementById('validGalleryLightbox'))) {

    $('.gallery-lightbox').simpleLightbox({
        captionSelector: 'self',
    });
}

/*Script para el slider del home*/
if ($.contains(document.body, document.getElementById('slider-main'))) {

    var mySwiper = new Swiper('#slider-main', {
        loop: true,
        autoplay: {
            delay: 5000,
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
    });

    $(document).on('click', '.block-banner-main .btn-light', function(event) {
        event.preventDefault();

        var item = $(this).data('item');

        if (item) {
            mySwiper.slidePrev();
        } else {
            mySwiper.slideNext();
        }

    });
}

$('.btn-group-target').on('click', '.btn-secondary', function(event) {
    event.preventDefault();

    var target = $(this).data('target');

    $('body,html').animate({ scrollTop: $(target).offset().top - 120 }, 2000, 'swing');
});

/*Se agregan las animaciones para toda la pagina que no cargan de menera recurrente*/
wow.init();